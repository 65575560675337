<template>
	<div
		class="cassie-vertical-md"
	>
		<SectionCard>
			<template #title>
				Access Key Details
			</template>
			<template #body>
				<v-row dense>
					<v-col cols="6">
						<TextField
							v-model="apiKey.apiKeyName"
							:disabled="!userFullPermissions"
							label="Access Key Name *"
							required="true"
							reserve-tooltip-space
						/>
					</v-col>
					<v-col cols="6">
						<TextField
							v-model="apiKey.apiKeyDescription"
							:disabled="!userFullPermissions"
							label="Description"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<TextField
							v-model="apiKey.tokenExpirationInMinutes"
							:disabled="!userFullPermissions"
							label="Expiry in Minutes *"
							type="number"
							tooltip-text="Input the length of time that your API Access Key 'Bearer Token' is active for, up to a maximum of 1440 minutes (24 Hours), after which you'll need to reauthenticate."
							:rules="{ required: true, numeric: true, betweenMinutes: [1, 1440] }"
						/>
					</v-col>
					<v-col cols="6">
						<v-switch
							v-model="allEndpoints"
							:disabled="!userFullPermissions"
							label="Has full permissions on all APIs"
							dense
							hide-details
							class="ma-0"
						/>
					</v-col>
				</v-row>
			</template>
		</SectionCard>
		<div
			v-if="!allEndpoints"
			:disabled="!userFullPermissions"
			class="d-flex align-center cassie-horizontal-md"
			style="flex: 1;"
		>
			<SecondaryActionButton
				:input-value="showFilters"
				:disabled="!userFullPermissions"
				@click="showFilters = !showFilters"
			>
				<v-icon>
					mdi-filter
				</v-icon>
				Filter Endpoints
			</SecondaryActionButton>
			<TextField
				v-if="showFilters"
				v-model="searchQuery"
				label="Endpoint Name"
				append-icon="mdi-magnify"
			/>
			<Dropdown
				v-if="showFilters"
				v-model="selectedEndpointType"
				label="Endpoint Method"
				:items="endpointMethodFilter"
			/>
		</div>
		<div
			v-show="!allEndpoints"
			:disabled="!userFullPermissions"
			class="cassie-vertical-md"
		>
			<SectionCard
				v-for="api in apis"
				:key="api.apiId"
				collapsible
				:collapsed="true"
			>
				<template #title>
					<span class="text-h6">
						{{ api.apiName }}
					</span>
					<span class="text-subtitle-1 px-1 pt-1">
						{{ ' - ' + api.apiDescription }}
					</span>
				</template>
				<template #body>
					<DataTable
						:headers="tableHeaders"
						:items="filteredEndpoints(api.endpoints)"
						:disabled="!userFullPermissions"
						sort-by="apiEndpointUrl"
						:sort-desc="false"
					>
						<template #item.apiEndpointDisplayName="{item}">
							<span>
								{{ item.apiEndpointDisplayName }}
								<v-tooltip
									v-if="item.apiEndpointTooltip"
									slot="append"
									top
								>
									<template #activator="{ on }">
										<v-icon
											light
											v-on="on"
										>
											mdi-information
										</v-icon>
									</template>
									<span>
										{{ item.apiEndpointTooltip }}
									</span>
								</v-tooltip>
							</span>
						</template>
						<template #item.apiEndpointType="{item}">
							<td :class="getEndpointColour(item.apiEndpointType)">
								{{ item.apiEndpointType }}
							</td>
						</template>
						<template #item.isActive="{item}">
							<v-switch
								:input-value="getValue(item.endpointId,api.apiId)"
								:disabled="!userFullPermissions"
								@change="setApiKeyEndpoints(item.endpointId,api.apiId, $event)"
							/>
						</template>
						<template #item.apiEndpointDocumentationLink="{item}">
							<v-icon
								@click="openDeveloperDocs(item.apiEndpointDocumentationLink)"
							>
								mdi-open-in-new
							</v-icon>
						</template>
					</DataTable>
				</template>
			</SectionCard>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import { brandOptions } from '../../../../../../shared/state/brands.js'
import { getAllEndpoints, updateApiKey, createApiKey } from '../../../../../../shared/utils/api/access-keys.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { API_IDS } from '../../../../../../shared/enums/access-keys.js'
import { getEnv } from '../../../../../../shared/utils/runtime-settings.js'
import { ACCESS_KEYS_MODULE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: { SectionCard, TextField, DataTable, SecondaryActionButton, Dropdown },
	props: {
		apiKeyToEdit: Object,
		isEdit: Boolean
	},
	setup () {
		return {
			brandOptions,
			API_IDS,
			showSnackbar
		}
	},
	data () {
		return {
			apiKey: JSON.parse(JSON.stringify(this.apiKeyToEdit || {
				apiKeyName: '',
				apiKeyDescription: '',
				endpointIds: [],
				standardApiEndpointIds: [],
				brandId: null,
				tokenExpirationInMinutes: 20
			})),
			apis: [],
			sapiUrl: getEnv('VUE_APP_BASE_URL'),
			tapiUrl: getEnv('VUE_APP_BASE_URL_TRANSACTIONAL'),
			standardapiUrl: getEnv('VUE_APP_BASE_URL_STANDARD'),
			sapiName: getEnv('VUE_APP_SAPIAPI_NAME'),
			tapiName: getEnv('VUE_APP_TRANSACTIONALAPI_NAME'),
			standardApiName: getEnv('VUE_APP_STANDARDAPI_NAME'),
			sapiDescription: getEnv('VUE_APP_SAPIAPI_DESCR'),
			tapiDescription: getEnv('VUE_APP_TRANSACTIONALAPI_DESCR'),
			standardApiDescription: getEnv('VUE_APP_STANDARDAPI_DESCR'),
			showFilters: false,
			searchQuery: null,
			selectedEndpointType: null,
			allEndpoints: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		tableHeaders () {
			return [
				{
					text: 'Method',
					value: 'apiEndpointType',
					width: '10%'
				},
				{
					text: 'Name',
					value: 'apiEndpointDisplayName',
					width: '35%'
				},
				{
					text: 'Active',
					value: 'isActive',
					width: '10%'
				},
				{
					text: 'Endpoint URL',
					value: 'apiEndpointUrl',
					width: '25%'
				},
				{
					text: 'Link to Documentation',
					value: 'apiEndpointDocumentationLink',
					width: '15%'
				}
			]
		},
		endpointMethodFilter () {
			return [
				{
					value: null,
					text: 'View All'
				},
				{
					value: 'GET',
					text: 'GET'
				},
				{
					value: 'POST',
					text: 'POST'
				},
				{
					value: 'PUT',
					text: 'PUT'
				},
				{
					value: 'DELETE',
					text: 'DELETE'
				}
			]
		},
		userFullPermissions () {
			return this.productAreaPermission(ACCESS_KEYS_MODULE_FULL_PERMISSIONS)
		},
		hasStandardApi () {
			return !!this.apis.find(({ apiId }) => apiId === API_IDS.STANDARDAPI)?.length
		},
		sapiEndpointIds () {
			return this.apis.find(({ apiId }) => apiId === API_IDS.SAPI).endpoints.map(({ endpointId }) => endpointId)
		},
		tapiEndpointIds () {
			return this.apis.find(({ apiId }) => apiId === API_IDS.TAPI).endpoints.map(({ endpointId }) => endpointId)
		},
		standardApiEndpointIds () {
			return this.apis.find(({ apiId }) => apiId === API_IDS.STANDARDAPI).endpoints.map(({ endpointId }) => endpointId)
		}
	},
	async created () {
		const { data: { apis } } = await getAllEndpoints()
		this.apis = apis
		this.setApiUrls()
		this.isAllEndpoints()
	},
	methods: {
		setApiUrls () {
			const sapi = this.apis.find(({ apiId }) => apiId === API_IDS.SAPI)
			if (sapi != null) {
				sapi.apiUrl = this.sapiUrl
				sapi.apiDescription = this.sapiDescription
				sapi.apiName = this.sapiName
			}
			const tapi = this.apis.find(({ apiId }) => apiId === API_IDS.TAPI)
			if (tapi != null) {
				tapi.apiUrl = this.tapiUrl
				tapi.apiDescription = this.tapiDescription
				tapi.apiName = this.tapiName
			}
			const standardapi = this.apis.find(({ apiId }) => apiId === API_IDS.STANDARDAPI)
			if (standardapi != null) {
				standardapi.apiUrl = this.standardapiUrl
				standardapi.apiDescription = this.standardApiDescription
				standardapi.apiName = this.standardApiName
			}
		},
		isAllEndpoints () {
			const allSapiAndTapiEndpoints = [...this.sapiEndpointIds, ...this.tapiEndpointIds]
			if (!this.hasStandardApi) {
				this.allEndpoints = this.apiKey.endpointIds.length === allSapiAndTapiEndpoints.length
			} else {
				this.allEndpoints = this.apiKey.endpointIds.length === allSapiAndTapiEndpoints.length &&
				this.apiKey.standardApiEndpointIds.length === this.standardApiEndpointIds.length
			}
		},
		filteredEndpoints (endpoints) {
			return endpoints.filter(({ apiEndpointDisplayName, apiEndpointType }) => {
				let check = true
				if (this.searchQuery) check = apiEndpointDisplayName.toLowerCase().includes(this.searchQuery.toLowerCase())
				if (this.selectedEndpointType !== null) check = check && apiEndpointType === this.selectedEndpointType
				return check
			})
		},
		getValue (endpointId, apiId) {
			if (apiId === API_IDS.STANDARDAPI) {
				return this.apiKey.standardApiEndpointIds.includes(endpointId)
			}
			return this.apiKey.endpointIds.includes(endpointId)
		},
		setApiKeyEndpoints (endpointId, apiId, value) {
			if (value) {
				if (apiId === API_IDS.STANDARDAPI) {
					this.apiKey.standardApiEndpointIds.push(endpointId)
				} else {
					this.apiKey.endpointIds.push(endpointId)
				}
			} else {
				if (apiId === API_IDS.STANDARDAPI) {
					const index = this.apiKey.standardApiEndpointIds.findIndex(
						endpoint => endpoint === endpointId
					)
					this.apiKey.standardApiEndpointIds.splice(index, 1)
				} else {
					const index = this.apiKey.endpointIds.findIndex(
						endpoint => endpoint === endpointId
					)
					this.apiKey.endpointIds.splice(index, 1)
				}
			}
		},
		getEndpointColour (endpointType) {
			const GET = 'GET'
			const POST = 'POST'
			const PUT = 'PUT'
			const DELETE = 'DELETE'

			if (endpointType === GET) {
				return 'endpoint-get'
			} else if (endpointType === POST) {
				return 'endpoint-post'
			} else if (endpointType === PUT) {
				return 'endpoint-put'
			} else if (endpointType === DELETE) {
				return 'endpoint-delete'
			} else {
				return ''
			}
		},
		openDeveloperDocs (apiEndpointDocumentationLink) {
			window.open(apiEndpointDocumentationLink, '_blank')
		},
		async saveApiKey () {
			if (this.allEndpoints) {
				this.apiKey.endpointIds = (this.apis.filter(({ apiId }) => {
					let check = true
					if (apiId === 3) check = false
					return check
				}).map(dataSet1 => dataSet1.endpoints.map(dataSet2 => dataSet2.endpointId)).reduce(function (prev, next) {
					return prev.concat(next)
				}))
				const standardApiEndpointIds = this.apis.filter(({ apiId }) => apiId === 3)
				if (standardApiEndpointIds.length) {
					this.apiKey.standardApiEndpointIds = standardApiEndpointIds.map(dataSet1 => dataSet1.endpoints.map(dataSet2 => dataSet2.endpointId)).reduce(function (prev, next) {
						return prev.concat(next)
					})
				}
			}
			if (!this.allEndpoints && !this.apiKey.endpointIds?.length && !this.apiKey.standardApiEndpointIds?.length) {
				showSnackbar({ color: 'red', text: 'You must add at least 1 endpoint to this API Key' })
				return
			}

			if (!this.isEdit) {
				const newApiKey = {
					apiKeyName: this.apiKey.apiKeyName,
					brandIds: [0],
					endpointIds: this.apiKey.endpointIds,
					standardApiEndpointIds: this.apiKey.standardApiEndpointIds,
					apiKeyDescription: this.apiKey.apiKeyDescription,
					tokenExpirationInMinutes: this.apiKey.tokenExpirationInMinutes
				}
				await createApiKey(newApiKey)
			} else {
				const updatedApiKey = {
					apiKeyId: this.apiKey.apiKeyId,
					apiKeyName: this.apiKey.apiKeyName,
					brandIds: [0],
					endpointIds: this.apiKey.endpointIds,
					standardApiEndpointIds: this.apiKey.standardApiEndpointIds,
					apiKeyDescription: this.apiKey.apiKeyDescription,
					apiKey: this.apiKey.apiKey,
					tokenExpirationInMinutes: this.apiKey.tokenExpirationInMinutes
				}
				await updateApiKey(updatedApiKey)
			}
			this.$emit('back-to-overview')
		}
	}
}
</script>
