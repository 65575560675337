<template>
	<ValidationForm #default="{ handleSubmit }">
		<ViewLayout>
			<template #header-title>
				{{ pageTitle }}
			</template>
			<template #header-caption>
				{{ pageCaption }}
			</template>
			<template #content>
				<BasicAccessKeyForm
					v-if="accessKeyType !== ACCESS_KEY_TYPES.API_KEY"
					ref="basicAccessKeyForm"
					:access-key-type="accessKeyType"
					:access-key-to-edit="accessKeyToEdit"
					:is-edit="isEdit"
					@back-to-overview="backToOverview"
					@load-access-keys="$emit('load-access-keys')"
				/>
				<ApiKeyForm
					v-else
					ref="apiKeyForm"
					:is-edit="isEdit"
					:api-key-to-edit="accessKeyToEdit"
					@back-to-overview="backToOverview"
					@load-access-keys="$emit('load-access-keys')"
				/>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="backToOverview">
							Back to Overview
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="userFullPermissions"
							@click="handleSubmit(submit)"
						>
							{{ !isEdit ? 'Create' : 'Save' }}
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</ViewLayout>
	</ValidationForm>
</template>

<script>
import { mapGetters } from 'vuex'
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import BasicAccessKeyForm from './basic-access-key-form.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import ApiKeyForm from './api-key-form.vue'
import { ACCESS_KEYS } from '../../../../router/route-names.js'
import { ACCESS_KEY_TYPES } from '../../../../../../shared/enums/access-keys.js'
import { ACCESS_KEYS_MODULE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: {
		ValidationForm,
		ViewLayout,
		BasicAccessKeyForm,
		ApiKeyForm,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton
	},
	props: {
		accessKeyType: {
			type: Number,
			required: true
		},
		accessKeyToEdit: Object
	},
	data () {
		return {
			ACCESS_KEY_TYPES
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userFullPermissions () {
			return this.productAreaPermission(ACCESS_KEYS_MODULE_FULL_PERMISSIONS)
		},
		isEdit () {
			return !!this.accessKeyToEdit
		},
		accessKeyName () {
			if (this.accessKeyType === ACCESS_KEY_TYPES.API_KEY) {
				return 'API Key'
			} else if (this.accessKeyType === ACCESS_KEY_TYPES.COOKIE_ACCESS_KEY) {
				return 'Cookie Access Key'
			} else {
				return 'Preference Widget Access Key'
			}
		},
		pageTitle () {
			return `${this.isEdit ? 'Edit' : 'Create'} ${this.accessKeyName}`
		},
		pageCaption () {
			return this.isEdit ? this.accessKeyToEdit.accessKey : 'Add a new access key'
		}
	},
	methods: {
		backToOverview () {
			this.$router.push({
				name: ACCESS_KEYS,
				params: {
					accessedVia: this.accessKeyType
				}
			})
		},
		submit () {
			if (this.accessKeyType === ACCESS_KEY_TYPES.API_KEY) {
				return this.$refs.apiKeyForm.saveApiKey()
			} else {
				return this.$refs.basicAccessKeyForm.saveAccessKey()
			}
		}
	}
}
</script>
